/* Component Dependencies */
var quickLinkItemTemplate = require('templates/quickLinkItem.hbs');
var quickLinkItemLanguageSelectorTemplate = require('templates/quickLinkItem-languageSelector.hbs');
var AriesComponent = require('libs/aries-component');
var Popup = require('libs/popup');
var AutoComplete = require('utils/autoCompleteTypeHandler');

AriesComponent.create({
  type: 'quickLinkItem',
  template: {
    'quickLinkItem': quickLinkItemTemplate,
    'quickLinkItem-languageSelector': quickLinkItemLanguageSelectorTemplate
  },
  unBindEvents: function($) {
    $('[data-component-id="' + this.$el.data('component-id') + '"] *').off();
    $('[data-component-id="' + this.$el.data('component-id') + '"]').off();
  },
	
  bindEvents: function() {
    'use strict';
    var _self = this;
    // code specific to headerLanguageSelection component here
			
    var signInToolTipPopUp = new Popup({
        selector: '.js-join-signIn-tooltip .mi-popover',
        sourceBlock: '.modal-content',
        alternateCloseMarkup: true
    });
    signInToolTipPopUp.register();
			
    if(this.$el.hasClass('mi-popover')) {
      var languageSelection = new Popup({
        selector: '.tile-quick-link-item.mi-popover',
        sourceBlock: '.modal-content',
        afterClose: function() {
         if(window.gigya) {
          window.gigya.__initialized = false;
        }
      }
    });  
      languageSelection.register();
    }

    if(this.$el.hasClass('mi-signin')) {
      var languageSelection = new Popup({
        selector: '.tile-quick-link-item.mi-signin',
        sourceBlock: '.modal-content',
        url: this.$el.data('modalTarget'),
        afterClose: function() {
         if(window.gigya) {
          window.gigya.__initialized = false;
        }
      }
    });  
      languageSelection.register();
    }
			
    $(document).ready(function () {
        if($('#tile-join-signIn-tooltip').hasClass('js-join-signIn-tooltip')) {
            $('#tile-join-signIn-tooltip').removeClass('l-display-none');
            $('.mi-signin, .m-profile-link-remembered').addClass('js-signIn-tooltip-mouseover');
        }
        setTimeout(function() {
            $('#tile-join-signIn-tooltip').addClass('l-display-none');
        }, 10000);
					
        $('.js-signIn-tooltip-mouseover').mouseover(function() {
            $('#tile-join-signIn-tooltip').removeClass('l-display-none');
        });
					
        $('.js-signIn-tooltip-mouseover').mouseleave(function() {
        	setTimeout(function() {
            $('#tile-join-signIn-tooltip').addClass('l-display-none');
        }, 10000);
        });
    });
			
  }
});

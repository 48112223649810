var AriesComponent = require('libs/aries-component');

var ComponentMapper = AriesComponent.extend({
  elOverride: true,
  initialize: function(props) {
    var _self = this;
    if (props) {
      _.each(props, function(value, key) {
        _self[key] = value;
      });
    }
  }
});

module.exports = ComponentMapper;

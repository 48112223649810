/**
 * This Utility Class generate and returns auto complete for various type of search handler i.e City
 */
'use strict';
var ComponentMapper = require('libs/component-mapper');
var $ = require('jquery');
var _ = require('lodash');

var properties = {
  templates: {
    city: _.template(
      '<% _.forEach(dataList, function(city, index) { %>' +
      '<li class="autocomplete-listitem <% if( index == 0 ) { %> autocomplete-listitem-active <% } %>" data-index="<%-index%>" data-hidden-input-code="<%-city.code%>" data-label="<%-city.label%><% if( city.region ) { %> <%-city.region%><% } %>, <%-city.country%>" >' +
      '<a class="ui-corner-all" >' +
      '<strong><%-city.label%></strong><% if( city.region ) { %> <%-city.region%><% } %>, <%-city.country%></a>' +
      '</li><% }); %>'),
    autoListHeader: _.template(
      '<li class="autocomplete-list-header">' +
      '<strong>Search Results</strong>' +
      '</li>')
  },
  events: {
    'keyup .js-auto-complete-input-type': 'getChoiceList',
    'keydown .js-auto-complete-input-type': 'navigateList',
    'blur .js-auto-complete-input-type': 'hideSuggestions'
  },
  init: function(){
    var _self = this;
    _self.searchType = _self.$el.data('autocomplete-type');
    _self.searchURL = _self.$el.data('type-search-url');
    _self.$scrollbarWrapper = $('<div class="autocomplete-type-scroller-wrapper"><ul></ul></div>');
    _self.$autoCompleteList = _self.$scrollbarWrapper.find('ul');
    
    _self.$autoCompleteList.addClass('ui-autocomplete').css({
        'z-index': 999,
        'top': '0px',
        'left': '0px',
        'position': 'relative',
        'display': 'none'
      })
      .on('mousedown', '.autocomplete-listitem', _.bind(_self.listItemSelect, _self))
      .on('mouseover', '.autocomplete-listitem', _.bind(_self.highLightListItem, _self));

    _self.$scrollbarWrapper.css({
      'display': 'none'
    });
    _self.getHiddenInputs(_self.$el.data());
    _self.$el.append(_self.$scrollbarWrapper)
    _self.subscribeDOMEvents();
  },
  /**
  * This function determines ajax call to get data for a specific keyword search.
  * @return null
  **/
  getChoiceList: function(evt) {
    var _self = this;
    var searchTerm = evt.target.value,
    parseCall = _.bind(_self.parseResult, _self, searchTerm, $(evt.target));
    _self.resethiddenInputData();
    if ( ((evt.keyCode >= 37) && (evt.keyCode <= 40)) || (evt.keyCode === 27) || (evt.keyCode === 9) || (evt.keyCode === 13)) {
      return;
    }
    _self.recentSearchTerm = searchTerm;
    _self.performTypeSearch(parseCall);
  },
  /**
  * This function is called when user navigates the list up and down with keyboard.
  * @return null
  **/
  navigateList: function(evt) {
    var _self = this;
    if(_self.suggestionListShow) {
      var keyCode = evt.keyCode || evt.which;
      var dataCount = parseInt(evt.target.getAttribute('data-count'));
      /**
      * Keycodes
      *  38 - Up
      *  40 - Down
      *  37 - Left
      *  39 - Right
      *  13 - Enter
      *  27 - Esc
      *  9 - Tab
      *  8 - Backspace
      **/
      switch(keyCode) {
        case 38:
        case 40:
          evt.preventDefault();
          var $selectedItem = _self.$autoCompleteList.find('.autocomplete-listitem-active');
          var dataIndex = $selectedItem.length && $selectedItem.attr('data-index') || -1;
          //For first element in list
          if(parseInt(dataIndex) === 0) {
            if(!_self.initialSearchTerm) {
              _self.initialSearchTerm = _self.$input.val();
            }
            if (keyCode === 38) {
              _self.recentSearchTerm = _self.initialSearchTerm;
              _self.$input.val(_self.recentSearchTerm);
              $selectedItem.removeClass('autocomplete-listitem-active');
              return;
            }
          }

          //For last element in list
          if (((parseInt(dataCount) - parseInt(dataIndex)) === 1) && keyCode === 40){
            _self.recentSearchTerm = _self.initialSearchTerm;
            _self.$input.val(_self.recentSearchTerm);
            $selectedItem.removeClass('autocomplete-listitem-active');
            return;
          }

          if( isFinite(dataIndex = parseInt(dataIndex)) ) {

            $selectedItem.removeClass('autocomplete-listitem-active');
            dataIndex = dataIndex + (keyCode - 39);

            if (dataIndex >= dataCount) {
              dataIndex -= dataCount;
            } else if( dataIndex < 0 ) {
              dataIndex = Math.max(-1, dataIndex) + dataCount;
            }

            $selectedItem = _self.$autoCompleteList.find('[data-index="' + dataIndex + '"]');
            $selectedItem.addClass('autocomplete-listitem-active');
          }
          
          _self.recentSearchTerm = $selectedItem.attr('data-label');
          _self.$input.val( _self.recentSearchTerm );

          break;
        case 37:
        case 39:
          _self.recentSearchTerm = _self.initialSearchTerm;
          break;
        case 13:
          if(_self.$autoCompleteList.find('.autocomplete-listitem-active').length) {
            evt.preventDefault();
            _self.listItemSelect(evt);
          }
          break;
        case 27:
          _self.hideSuggestions(evt);
          evt.preventDefault();
          break;
        case 9:
          _self.listItemSelect(evt);
          break;
        default:
          break;
      }
    }
  },
  /**
  * This function helps in hiding autocomplete list.
  * @return null
  **/
  hideSuggestions: function(evt) {
    this.$scrollbarWrapper.hide();
    this.suggestionListShow = false;
  },
  /**
  * This function performs diffrent type of search on basis of search term and search type.
  * @return null
  **/
  xhrRequest: false,
  performTypeSearch: function(parseCall) {
    var _self = this;
    var searchRequest = {
        method: 'GET',
        url: _self.searchURL,
        cache: true,
        data: {
          type : _self.searchType,
          value : _self.recentSearchTerm
        },
        success: parseCall
      };

      if(_self.xhrRequest) {
        _self.xhrRequest.abort();
      }
      _self.xhrRequest = _self.makeAjaxCall(searchRequest);
  },
  /**
  * This function generates autocomplete list as per API response.
  * @return null
  **/
  parseResult: function(searchTerm, inputTarget, data) {
    var _self = this;
    var count = 0;
    var listHtml = '';
    if (data.component.data.content.length) {
      listHtml += _self.templates.autoListHeader({});
    }
    listHtml += _self.templates[_self.searchType]({dataList: data.component.data.content});
    _self.listCount = data.component.data.content.length - 1;
    _self.$input = inputTarget;
    _self.showList(listHtml);
  },
  /**
  * This function sets autocomplete list to its container and modifies css accordingly.
  * @return null
  **/
  showList: function(listHtml) {
    var _self = this;
    _self.$autoCompleteList.html(listHtml);
    _self.$scrollbarWrapper.css({          
      width: _self.$input.outerWidth() + 'px'
    });
    _self.$autoCompleteList.css({          
      left: '0px',
      width: _self.$input.outerWidth() + 'px'
    });
    
    _self.$input.attr('data-count', _self.listCount);
    _self.suggestionListShow = _self.listCount > 0;
    _self.$autoCompleteList.show();
    _self.$scrollbarWrapper.show();
  },
  /**
   * This function highlights current option on hover in autocomplete list.
   * @return null
  **/
  highLightListItem: function(evt) {
    var _self = this;
    if ( evt.target ) {
      evt.stopImmediatePropagation();
      _self.$autoCompleteList.find('.autocomplete-listitem-active').removeClass('autocomplete-listitem-active');
      if(!$(evt.target).hasClass('t-category-header')) {
        var $thisTarget = (evt.target.nodeName == "LI")? $(evt.target): $(evt.target).closest('li');
        $thisTarget.addClass('autocomplete-listitem-active');
      }
    }
  },
  /**
  * This function handles click on an item selected through autocomplete list.
  * @return null
  **/
  listItemSelect: function(evt) {
    var _self = this;
    var $selectedItem = _self.$autoCompleteList.find('.autocomplete-listitem-active');
    // Won't set location field value if selected item is not available
    if ($selectedItem.length) {
      _self.hideSuggestions();
    }
    _self.sethiddenInputData($selectedItem.data());
    _self.$input.val($selectedItem.data('label'));
  },
  /**
  * This function is used to create the array of hidden inputs for setting context data
  * @return null
  **/
  getHiddenInputs: function(dataSet) {
    var _self = this;
    _self.hiddenInputs = {};
    Object.keys(dataSet).forEach(function(key) {
      if (key.startsWith('hidden')) {
        _self.hiddenInputs[key] = _self.$parent.$el.find("input[name='"+ dataSet[key] +"']");
      }
    });
  },
  /**
  * This function is used to set the context data on hidden inputs
  * @return null
  **/
  sethiddenInputData: function(selectedItemDataSet) {
    var _self = this;
    Object.keys(_self.hiddenInputs).forEach(function(key) {
      $(_self.hiddenInputs[key]).val(selectedItemDataSet[key]);
    });
  },
  /**
  * This function is used to reset the context data on hidden inputs
  * @return null
  **/
  resethiddenInputData: function() {
    var _self = this;
    Object.keys(_self.hiddenInputs).forEach(function(key) {
      $(_self.hiddenInputs[key]).val('');
    });
  }
};

module.exports = {
  create: function(props) {
    var obj = _.cloneDeep(properties);
    var AutocompleteInstance = ComponentMapper.extend(obj);
    return new AutocompleteInstance(props);
  }
}